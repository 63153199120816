import { Configuration, RedirectRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msal: Configuration = {
  system: { allowRedirectInIframe: true },
  auth: {
    clientId: "f8abdc34-75a1-4527-be8c-89579eef41d2",
    authority:
      "https://login.microsoftonline.com/b27efc6e-cab2-414d-a875-65b77cb47ca6",
    redirectUri: "/signin/callback",
    postLogoutRedirectUri: "/",
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: [
    "openid",
    "offline_access",
    "profile" /*"User.Read", "User.ReadBasic.All"*/,
  ],
};

export const awsStsSessionDuration = 3600;

export const awsRoleArn = "arn:aws:iam::867274495068:role/butastic-user-role";
