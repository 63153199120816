import React, { ReactNode, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import * as AuthConfig from "~/config/authConfig";
import {
  Button,
  FluentProvider,
  Spinner,
  webDarkTheme,
} from "@fluentui/react-components";
import { ArrowCounterclockwiseFilled } from "@fluentui/react-icons";
import { RevisionComponent } from "./components/RevisionComponent";

const StackTrace: React.FC<{ stack: string | undefined }> = ({ stack }) => {
  if (!stack) {
    return <>{"(None)"}</>;
  }

  return (
    <ul className="list-none">
      {stack.split("\n").map((entry) => (
        <li>{entry}</li>
      ))}
    </ul>
  );
};

class ErrorBoundary extends React.Component<
  { children?: ReactNode },
  {
    hasError: boolean;
    error: Error | undefined;
    errorInfo: { componentStack: string } | undefined;
  }
> {
  constructor(props: { children?: ReactNode }) {
    super(props);
    this.state = { hasError: false, error: undefined, errorInfo: undefined };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: { componentStack: string }) {
    console.error("Fatal error", error, errorInfo);
    this.setState({ hasError: true, error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="block text-left text-white flex-col bg-[var(--colorNeutralBackground1)]">
          <h1 className="text-center">Something went wrong.</h1>
          <div>
            <span>Error:</span>
            {this.state.error !== undefined ? (
              <ul className="list-none">
                <li>Name: {this.state.error.name}</li>
                <li>Message: {this.state.error.message}</li>
                <li>
                  Stack: <StackTrace stack={this.state.error.stack} />
                </li>
              </ul>
            ) : (
              <span>(None)</span>
            )}
          </div>
          <div>
            <span>Error Info: </span>
            <StackTrace stack={this.state.errorInfo?.componentStack} />
          </div>
          <div className="flex space-x-2 justify-center">
            <Button
              appearance="primary"
              onClick={() => window.location.reload()}
              icon={<ArrowCounterclockwiseFilled />}
            >
              Reload page
            </Button>
          </div>
          <RevisionComponent />
        </div>
      );
    }

    return this.props.children;
  }
}

const App = React.lazy(() => import("./App"));
export const msalInstance = new PublicClientApplication(AuthConfig.msal);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

const rootElement = document.getElementById("root") as HTMLDivElement;
const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <FluentProvider theme={webDarkTheme}>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <MsalProvider instance={msalInstance}>
            <App />
          </MsalProvider>
        </Suspense>
      </ErrorBoundary>
    </FluentProvider>
  </React.StrictMode>
);
