import React from "react";

export function RevisionComponent() {
  return (
    <div className="opacity-40 mt-12 flex flex-col sm:flex-row justify-center">
      <div>
        Revision:{" "}
        {import.meta.env.GITHUB_SHA?.substring(0, 8) || "development version"}{" "}
        on {import.meta.env.GITHUB_REF_NAME || "n/a"}
      </div>
    </div>
  );
}
